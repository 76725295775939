import { useQuery, useQueryClient } from "@tanstack/vue-query";

const fetchIngredientsMap = async () => {
  console.log("Fetch map!");
  const res = await fetchWithAuth("/api/ingredients/map");

  console.log(res);

  return res;
};

export const useIngredientsMapQuery = () => {
  return useQuery({
    queryKey: ["ingredients", "map"],
    queryFn: fetchIngredientsMap,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
};

export const useIngredientsMapPrefetch = () => {
  const queryClient = useQueryClient();

  return () =>
    queryClient.prefetchQuery({
      queryKey: ["ingredients", "map"],
      queryFn: fetchIngredientsMap,
      // staleTime: 600000, // 10 minutes
    });
};
