import { useQuery, useQueryClient } from "@tanstack/vue-query";

const fetchRecyclingMap = async () => {
  console.log("Fetch map!");
  const res = await fetchWithAuth("/api/recycling/map");

  console.log(res);

  return res;
};

export const useRecyclingMapQuery = () => {
  return useQuery({
    queryKey: ["recycling", "map"],
    queryFn: fetchRecyclingMap,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
};

export const useRecyclingMapPrefetch = () => {
  const queryClient = useQueryClient();

  return () =>
    queryClient.prefetchQuery({
      queryKey: ["recycling", "map"],
      queryFn: fetchRecyclingMap,
      // staleTime: 600000, // 10 minutes
    });
};
