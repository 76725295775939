import { useQuery, useQueryClient } from "@tanstack/vue-query";

const fetchIngredientsTranslations = async () => {
  console.log("Fetch translations!");
  const res = await fetchWithAuth("/api/ingredients/translations");

  console.log(res);

  return res;
};

export const useIngredientsTranslationsQuery = () => {
  return useQuery({
    queryKey: ["ingredients", "translations"],
    queryFn: fetchIngredientsTranslations,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
};

export const useIngredientsTranslationsPrefetch = () => {
  const queryClient = useQueryClient();

  return () =>
    queryClient.prefetchQuery({
      queryKey: ["ingredients", "translations"],
      queryFn: fetchIngredientsTranslations,
      // staleTime: 600000, // 10 minutes
    });
};
