import { useQuery, useQueryClient } from "@tanstack/vue-query";

const fetchRecyclingTranslations = async () => {
  console.log("Fetch translations!");
  const res = await fetchWithAuth("/api/recycling/translations");

  console.log(res);

  return res;
};

export const useRecyclingTranslationsQuery = () => {
  return useQuery({
    queryKey: ["recycling", "translations"],
    queryFn: fetchRecyclingTranslations,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
};

export const useRecyclingTranslationsPrefetch = () => {
  const queryClient = useQueryClient();

  return () =>
    queryClient.prefetchQuery({
      queryKey: ["recycling", "translations"],
      queryFn: fetchRecyclingTranslations,
    });
};
